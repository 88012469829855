
            @import "src/scss/index.scss";
          
// Colors
$team-navbar-title: $color-primary-80;
$team-navbar-option: $color-primary-80;
$team-navbar-active-option: $color-primary-100;
$team-card: $color-primary-90;
$team-card-title: $color-primary-0;
$team-card-name: $color-primary-0;

.team {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content !important;

  &__navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 48px;
    padding: 40px 0 0;
    margin-bottom: 40px;

    // height: 50px;

    h3 {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 28.43px;
      color: $team-navbar-title;
      margin: 0;
      text-transform: uppercase;
    }

    &-options {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;

      &-option {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 28.43px;
        color: $team-navbar-option;
        cursor: pointer;
      }

      .active {
        font-weight: 700;
        color: $team-navbar-active-option;
      }
    }
  }

  &__body {
    display: grid;
    gap: 48px;
    width: 100%;
  }
}

@media (min-width: $breakpoint-lg) {
  .team {
    &__navbar {
      flex-direction: row;
      align-items: center;
      &-options {
        gap: 32px;
        margin-bottom: 0;
      }
    }
    &__body {
      gap: 0;
      & > * {
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
