
            @import "src/scss/index.scss";
          
.what-we-do {
  height: 100vh !important;
  &,
  .wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
  }
  h2,
  h3,
  h4 {
    margin: 0;
    margin-bottom: 32px;
  }
  h2 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 21.328px;
    text-align: center;
    margin-bottom: 16px;
    // line-height: 28px;
    text-transform: uppercase;
  }
  h3,
  h4 {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 28.43px;
    // line-height: 35px;
    color: #1b3156;
    text-align: center;
  }
  h4 {
    color: #1b3156;
    // background: $linear-gradient;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // text-fill-color: transparent;
  }
}

@media (min-width: $breakpoint-md) {
  .what-we-do {
    height: calc(100vh - ($navbar-height * 2)) !important;

    h2 {
      font-size: 28.43px;
      margin-bottom: 32px;
    }
    h3,
    h4 {
      font-size: 37.9px;
      text-align: left;
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .what-we-do {
    height: calc(100vh - $navbar-height) !important;
    h2 {
      text-align: left;
    }
    h3,
    h4 {
      font-size: min(3.58vw, 51.52px);
    }
  }
}
