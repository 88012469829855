
            @import "src/scss/index.scss";
          
// Colors
$landing-background-color: radial-gradient(50% 50% at 50% 50%, #ccdfff 0%, #f5f9ff 100%);

.landing {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  &__title {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 28.43px;
    line-height: 37px;
    text-align: center;
    color: $color-primary-90;
    margin-top: 0;
  }

  &__fullscreen {
    & > * {
      position: relative;
      display: block;
      width: 100%;
      height: max(calc(100vh - $navbar-height), 100%);
      padding-top: $navbar-height;
      background: $landing-background-color;
    }
  }
  &__home {
    height: 100vh;
    padding-top: 0;
  }
}

@media (min-width: $breakpoint-lg) {
  .landing {
    &__title {
      text-align: left;
    }
  }
}
