
            @import "src/scss/index.scss";
          
// Colors
$navbar-background-color: #f2f7ff;
$navbar-text-color: $color-primary-90;

.Navbar {
  align-items: center;
  background-color: $navbar-background-color;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: $navbar-height;
  left: 0;
  padding: 0 32px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 6;

  &__logo {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      width: 101px;
    }
  }

  &__options {
    height: 100%;
    &,
    ul {
      padding: 0;
      display: none;
      align-items: center;
      justify-content: flex-end;
      width: calc(100vw - (32px * 2));
    }
    ul {
      list-style-type: none;
      position: relative;
      gap: 42px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $color-primary-90;

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  &__Hamburger {
    border-radius: 321px;
    background-color: $color-neutral-100;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: none;
    transform: scale(0.6);
    transform-origin: right;
    .hamburger-react {
      * {
        background: $color-primary-0 !important;
      }
    }
  }
}

.animation-image {
  -webkit-animation: 2.5s ease 0s normal forwards 1 titleAnimation;
  animation: 2.5s ease 0s normal forwards 1 titleAnimation;
}

.animation-item {
  li {
    &:first-child {
      -webkit-animation: 1.8s ease 0s normal forwards 1 titleAnimation;
      animation: 1.8s ease 0s normal forwards 1 titleAnimation;
    }

    &:nth-child(2) {
      -webkit-animation: 2.3s ease 0s normal forwards 1 titleAnimation;
      animation: 2.3s ease 0s normal forwards 1 titleAnimation;
    }

    &:nth-child(3) {
      -webkit-animation: 2.8s ease 0s normal forwards 1 titleAnimation;
      animation: 2.8s ease 0s normal forwards 1 titleAnimation;
    }

    &:nth-child(4) {
      -webkit-animation: 3.3s ease 0s normal forwards 1 titleAnimation;
      animation: 3.3s ease 0s normal forwards 1 titleAnimation;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .Navbar__Buttons {
    display: flex;
  }
}

@media (min-width: $breakpoint-md) {
  .Navbar {
    height: calc($navbar-height * 2);
    display: grid;

    &__options {
      &,
      ul {
        display: flex;
      }
      ul {
        justify-content: space-between;
      }
    }
    &__Hamburger,
    &Drawer {
      display: none;
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .Navbar {
    height: calc($navbar-height);
    display: flex;
    &__logo {
      img {
        width: 128px;
      }
    }
    &__options {
      width: 100%;
      ul {
        width: fit-content;
      }
    }
  }
}

@keyframes showLogo {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hideLogo {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200%);
  }
}

//
// Colors
$navbar-background-color: #f2f7ff;
$navbar-text-color: $color-primary-90;
