
            @import "src/scss/index.scss";
          
// Colors
$gallery-title: $color-primary-100;
$gallery-subtitle: $color-primary-100;
$gallery-carousel-dot: $color-primary-40;
$gallery-carousel-active-dot: $color-primary-90;
$gallery-button-color: $color-primary-90;
$gallery-image-box-color: $color-primary-0;
$gallery-image-box-text-color: $color-primary-100;
$gallery-button-color: $color-primary-100;
$gallery-button-active-color: $color-primary-90;

.gallery {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &__navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 70px 0;
    height: 50px;

    h3 {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 700;
      font-size: 37.897px;
      color: $gallery-title;
      margin: 0;
    }

    h4 {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 28.43px;
      color: $gallery-subtitle;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 90px);

    &-buttons {
      position: absolute;
      left: 8%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      z-index: 21;

      &-btn {
        background: none;
        border: none;
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $gallery-button-color;
        margin-bottom: 5px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

      .active {
        text-decoration-line: underline;
        color: $gallery-button-active-color;
      }
    }

    &-images {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 auto;

      &-slick {
        position: relative;
        display: block;
        width: 100%;
        max-width: 80%;
        height: 100%;
        margin: 0 auto;

        &-arrow-right,
        &-arrow-left {
          position: relative;
          display: inline-block;
          font-family: "Fira Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          padding: 5px 20px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          background: $color-primary-0;
          border-radius: 313px;
          border: none;
          color: $gallery-button-color;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.3s;
          z-index: 0;
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: "";
            background: $gallery-button-color;
            border-radius: 313px;
            transition: transform 0.3s;
            transform-origin: left top;
            transform: scale(1, 0);
          }

          &:hover {
            color: #fff;

            &::before {
              transform-origin: left bottom;
              transform: scale(1, 1);
            }
          }
        }

        &-arrow-right {
          position: absolute;
          left: 68%;
          bottom: 50px;
          z-index: 20;

          svg {
            transform: rotate(180deg);
          }
        }

        &-arrow-left {
          position: absolute;
          left: calc(68% + 85px);
          bottom: 50px;
          z-index: 20;
        }

        &-item {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
          }

          &-box {
            position: absolute;
            left: -70px;
            bottom: 50px;
            padding: 16px;
            background: $gallery-image-box-color;
            z-index: 20;
            visibility: hidden;

            span {
              font-family: "Fira Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              color: $gallery-image-box-text-color;
            }
          }

          .visible {
            visibility: visible;
          }
        }

        .slick-prev,
        .slick-next {
          z-index: 10;
        }

        .slick-prev:before,
        .slick-next:before {
          color: #000 !important;
        }

        .slick-list {
          height: 100%;

          .slick-track {
            display: flex;
            align-items: center;

            .slick-slide {
              opacity: 0.2;
              z-index: 9;
              cursor: pointer;
              transition: opacity 0.5s linear 0.4s;

              & > div {
                padding: 0 50px;
              }

              img {
                transition: transform 0.5s ease;
              }
            }

            .slick-active:not(.slick-center) {
              margin: 0 -70px;
            }

            .slick-center {
              opacity: 1;
              transition: opacity 0.5s linear;
              margin: 0;
              z-index: 10;

              img {
                width: calc(100% + 140px);
                transition: transform 0.5s ease;
                transform: scale(1.5);
                margin: 70px 0;
              }

              .slick {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .slick-dotted.slick-slider {
          margin-bottom: 30px;
        }

        .slick-dots {
          position: absolute;
          bottom: -10px;
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;
        }

        .slick-dots li {
          position: relative;
          display: inline-block;
          width: 10px;
          height: 10px;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          transition: width 0.3s ease-in-out;
        }

        .slick-dots li button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 10px;
          height: 10px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
        }

        .slick-dots li button:hover,
        .slick-dots li button:focus {
          outline: none;
        }

        .slick-dots li button:hover:before,
        .slick-dots li button:focus:before {
          opacity: 1;
        }

        .slick-dots li button:before {
          font-family: "slick";
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          content: "•";
          text-align: center;
          opacity: 0.25;
          color: black;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .slick-dots li.slick-active button:before {
          opacity: 0.75;
          color: black;
        }

        .ft-slick__dots--custom {
          height: 8px;
          width: 14px;
          background-color: $gallery-carousel-dot;
          // border-radius: 4px;
          position: relative;
        }

        .slick-dots li {
          width: 14px;
          margin: 0 2px;
          transition: width 0.3s ease-in-out;
        }

        .slick-dots .slick-active {
          width: 56px;
          transition: width 0.3s ease-in-out;

          & > div {
            top: -2px !important;
          }
        }

        .slick-dots .slick-active .ft-slick__dots--custom {
          width: 56px;
          top: 0px;
          overflow: hidden;
          background: #e5e7e9;

          .loading {
            height: 8px;
            animation: loading 5s ease-in;
            background: linear-gradient(270deg, $gallery-carousel-dot, $gallery-carousel-active-dot);
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            // border-radius: 4px;
          }
        }
      }
    }
  }
}

/* Custom Slick Dots */
@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
