
            @import "src/scss/index.scss";
          
// Colors
$ethic-left-background: $color-primary-20;
$ethic-left-title-color: $color-primary-40;
$ethic-left-btn-color: $color-primary-100;
$ethic-right-title-color: $color-primary-90;
$ethic-right-description-color: $color-primary-100;
$ethic-right-btn-color: $color-primary-90;

.ethic {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh !important;
  padding-top: 100px !important;

  .landing__title {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }
  &__slider {
    background-color: $color-primary-20;
    margin-bottom: 62px;
    .slick {
      &-list {
        padding: 0 $padding-wrapper;
      }
    }
    &__option {
      font-size: 21.328px;
      // text-align: center;
      opacity: 1;
      font-weight: 700;
      transition: all 0.5s ease;

      &-active {
        opacity: 0.32;
      }
    }
  }
  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;

    &-wrap {
      display: flex;
      justify-content: flex-end;

      // &:first-child {
      //   display: none;
      // }
      &:last-child {
        justify-content: flex-start;
      }

      &-left {
        background-color: $ethic-left-background;

        display: none;
        &-title {
          font-family: "Fira Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: $ethic-left-title-color;
          background: none;
          margin: 0 0 103px;
        }
      }

      &-right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        &-title {
          font-family: $font-secondary;
          font-style: normal;
          font-weight: 700;
          font-size: 28.43px;
          color: $ethic-right-title-color;
          letter-spacing: 0.05em;
          margin: 0;
          min-height: 2lh;
        }

        &-description {
          font-family: "Fira Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          color: $ethic-right-description-color;
          margin: 0 0 35px;

          a {
            text-decoration: none;
            color: $ethic-right-description-color;
          }
        }

        &-button {
          position: relative;
          display: inline-block;
          font-family: "Fira Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          padding: 16px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          background: none;
          color: $ethic-right-btn-color;
          border: 2px solid $ethic-right-btn-color;
          cursor: pointer;
          transition: 0.3s;
          z-index: 0;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: "";
            background: $ethic-right-btn-color;
            transition: transform 0.3s;
            transform-origin: left top;
            transform: scale(1, 0);
          }

          &:hover {
            color: #fff;

            &::before {
              transform-origin: left bottom;
              transform: scale(1, 1);
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
  .ethic {
    height: 100% !important;
  }
}

@media (min-width: $breakpoint-lg) {
  .ethic {
    height: calc(100vh - $navbar-height) !important;
    &__slider {
      display: none;
    }
    &__container {
      display: grid;
      grid-template-columns: 6fr 5fr;
      gap: calc($max-width-lg / 12);
      &-wrap {
        &-left {
          display: flex;
          padding: 32px;
          width: 100%;
          min-height: 200px;
          &-buttons {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            &-btn {
              font-family: "Fira Sans";
              font-style: normal;
              font-weight: 300;
              font-size: min(40.4764px, 2.8vw);
              line-height: 55px;

              background: none;
              color: $ethic-left-btn-color;
              border: none;
              text-transform: uppercase;
              cursor: pointer;
              line-height: 1;
              opacity: 0.32;
              text-align: left;
              transition: all 0.5s ease;
            }

            .active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
