
            @import "src/scss/index.scss";
          
.NavbarDrawer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  // background-color: $primary-black;
  background: $radial-gradient;
  background-size: 100vw;
  background-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  transition: all 0.5s ease;
  pointer-events: fill;
  padding: 0 32px 32px;
  &,
  a {
    text-decoration: none;
    color: $color-primary-60;
  }
  &__List {
    list-style: none;

    padding-left: 0;
    &__Item {
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 28.43px;
      line-height: 35px;
      font-family: "Fira Sans", serif;

      &--icon {
        display: flex;
        align-items: center;
        img {
          margin-left: 13px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__Footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    &__Siguenos {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 16px;
      gap: 14px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    &__Subtext {
      text-align: center;
      font-weight: 900;
      font-size: 13px;
      color: #6f6f6f;
      margin-bottom: 16px;
    }
    &__Copyright {
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 32px;
      color: #6f6f6f;
      text-align: center;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .NavbarDrawer {
    &__List {
      font-size: 40px;
      &__Item {
        margin-bottom: 40px;
        &:last-of-type {
          margin-bottom: 0;
          img {
            width: 38px;
            height: 38px;
          }
        }
      }
    }
    &__Footer {
      width: 100%;
      padding-right: 32px;
      padding-left: 32px;
      flex-direction: column;
      justify-content: space-between;
      &__Siguenos {
        img {
          width: 32px;
          height: 32px;
        }
      }
      &__Subtext {
        font-size: 20px;
      }
      &__Copyright {
        font-size: 20px;
      }
    }
  }
}
