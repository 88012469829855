
            @import "src/scss/index.scss";
          
.contact-form {
  height: 100vh !important;
  padding-top: 100px !important;
  //   height: fit-content !important;

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .landing__title {
      text-transform: uppercase;
    }
  }
  .wrapper,
  &__inputs {
    display: grid;
    gap: 24px;
  }
  &__footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    z-index: 1;
  }
  &__checkbox {
    &__label {
      font-size: 12px;
      line-height: 19px;
      a {
        color: $color-primary-100 !important;
      }
    }
  }
  button {
    background-color: $color-primary-100;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: $breakpoint-md) {
  .contact-form {
    padding-top: 50px;
    height: calc(100vh - ($navbar-height * 2)) !important;
    .wrapper {
      max-width: 544px;
    }
    &__checkbox {
      &__label {
        font-size: 21.328px;
        line-height: 28px;
      }
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .contact-form {
    height: calc(100vh - $navbar-height) !important;
    .wrapper {
      max-width: $max-width-lg;
    }
    &__inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      & > *:last-child {
        grid-column: span 2;
      }
    }
    &__footer {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    button {
      min-width: min(50%, 430px);
    }
  }
}
