
            @import "src/scss/index.scss";
          
// Colors
$home-text-color: $color-primary-90;
$navbar-text-color: $color-primary-90;

.home {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $home-text-color;

  &__blend {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/images/landing/home.jpeg") center center no-repeat; // fallback
    background-size: cover;
    mix-blend-mode: overlay;
  }

  &__logo {
    position: relative;
    display: block;
    margin: 0 auto;
    width: calc(100% - 60px);
    max-width: 650px;
    margin-bottom: 40px;
    // animation: 1s titleAnimation;
    padding: 0 30px;
    z-index: 1;
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 700;
    font-size: 50.517px;
    text-align: center;
    margin: 0 0 40px;
    animation: 2s titleAnimation;
    z-index: 1;
  }

  &__subtitle {
    width: 450px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 28.43px;
    text-align: center;
    color: $home-text-color;
    margin: 0 0 40px;
    // animation: 2s anim-lineUp ease-out;
    z-index: 1;
  }

  &__buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    // width: 500px;
    // animation: 2s anim-lineUp ease-out;
    z-index: 1;
    padding: 0;
    a {
      text-decoration: none;
      color: inherit;
    }
    & > * {
      position: relative;
      display: inline-block;
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      padding: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 0;

      &:first-child {
        -webkit-animation: 1s ease 0s normal forwards 1 titleAnimation;
        animation: 1s ease 0s normal forwards 1 titleAnimation;
      }

      &:nth-child(2) {
        -webkit-animation: 1.8s ease 0s normal forwards 1 titleAnimation;
        animation: 1.8s ease 0s normal forwards 1 titleAnimation;
      }

      &:nth-child(3) {
        -webkit-animation: 2.3s ease 0s normal forwards 1 titleAnimation;
        animation: 2.3s ease 0s normal forwards 1 titleAnimation;
      }

      &:nth-child(4) {
        -webkit-animation: 2.8s ease 0s normal forwards 1 titleAnimation;
        animation: 2.8s ease 0s normal forwards 1 titleAnimation;
      }
    }
  }

  &__lang {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-bottom: 96px;

    span {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: $navbar-text-color;
      text-transform: uppercase;
      cursor: pointer;
    }

    .option-active {
      font-weight: 700;
      text-decoration-line: underline;
    }
  }

  &__row {
    position: absolute;
    display: block;
    border-radius: 50%;
    z-index: 1;
    bottom: 48px;
    img {
      animation: 2s anim-lineUp ease-out;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .home {
    &__logo {
      width: 70%;
      padding: 0;
    }

    &__buttons {
      li {
        font-size: 22px;
      }
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .home {
    &__logo {
      width: 100%;
      padding: 0;
    }

    &__buttons {
      flex-direction: row;
    }
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
