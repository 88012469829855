
            @import "src/scss/index.scss";
          
// Colors
$map-title-color: $color-primary-100;
$map-title-active-color: #3561ab;
$map-description-color: $color-primary-100;
$map-redirect-color: $color-primary-80;
$map-button-color: $color-primary-90;
.map {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - $navbar-height);
  .landing__title {
    margin-top: 0;
    text-transform: uppercase;
  }

  &__container {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 40px;

    &-wrap {
      text-align: center;
      &-title {
        font-family: $font-secondary;
        font-style: normal;
        font-weight: 400;
        font-size: 28.43px;
        color: $map-title-color;
        margin: 0 0 48px;

        span {
          color: #1b3156;
          // background: $linear-gradient;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // background-clip: text;
          // text-fill-color: transparent;
        }
      }

      &-description {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $map-description-color;
        margin: 0 0 23px;
      }
      &-redirect,
      &-contact {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 21.328px;
        text-decoration: none;
        line-height: 28px;
      }
      &-contact {
        color: $color-primary-100;
        display: none;
        a {
          color: inherit;
        }
      }

      &-redirect {
        text-decoration-line: underline;
        color: $map-redirect-color;
        margin: 0 0 47px;
        display: none;
      }

      &-buttons {
        display: grid;
        gap: 24px;
        a {
          text-decoration: none;
          button {
            width: 100%;
          }
        }
      }

      &-iframe {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
  .map {
    height: calc(100vh - ($navbar-height * 2)) !important;
    &__container {
      &-wrap {
        &-description {
          display: none;
        }
        &-contact {
          display: block;
        }
      }
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .map {
    display: flex !important;
    height: calc(100vh - ($navbar-height)) !important;
    &__container {
      grid-template-columns: 4fr 7fr;
      grid-template-rows: none;
      gap: calc($max-width-lg / 11);
      &-wrap {
        text-align: left;
        &-redirect {
          display: block;
        }
      }
    }
  }
}
