//Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// wrapper
$padding-wrapper: 32px;
$max-width: calc(100% - ($padding-wrapper * 2));
// // md
$padding-wrapper-md: 88px;
$max-width-md: calc(100% - ($padding-wrapper-md * 2));
// // lg
$padding-wrapper-lg: 143px;
$max-width-lg: min(calc(100% - ($padding-wrapper-lg * 2)), 1350px);

$navbar-height: 76px;
