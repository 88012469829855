
            @import "src/scss/index.scss";
          
$home-text-color: $color-primary-90;
$navbar-text-color: $color-primary-90;

.Button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1ch;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: none;
  color: $home-text-color;
  text-transform: uppercase;
  border: 2px solid $home-text-color;
  cursor: pointer;
  transition: 0.3s;
  z-index: 0;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background: $home-text-color;
    transition: all 0.3s ease-in-out;
    transform-origin: left top;
    transform: scale(0, 1);
  }

  &:hover {
    color: #fff;

    &::before {
      background: $color-primary-40;
      transform-origin: left bottom;
      transform: scale(1, 1);
    }
  }

  &__contained {
    background-color: $home-text-color;
    color: #fff;
  }
  &:disabled {
    filter: grayscale(1);
    opacity: 0.6;
  }
}
