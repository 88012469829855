$color-primary-100: #1b3156;
$color-primary-90: #3661ab;
$color-primary-80: #2c4f8c;
$color-primary-60: #3b69ba;
$color-primary-40: #7396d3;
$color-primary-20: #b1c5e7;
$color-primary-0: #eff3fa;

$color-neutral-100: #242424;
$color-neutral-80: #575757;
$color-neutral-40: #8f8f8f;

$radial-gradient: radial-gradient(50% 50% at 50% 50%, #dce9ff 0%, #f5f9ff 100%);
$linear-gradient: linear-gradient(93.48deg, #4533b5 12.51%, #1f9c95 95.5%);
