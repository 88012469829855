
            @import "src/scss/index.scss";
          
@import "./scss/index.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
button {
  outline: none;
}

@keyframes titleAnimation {
  0% {
    // opacity: 0;
    -webkit-clip-path: polygon(100% 100%, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(100% 100%, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    // opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }
}
