/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Optima", serif;
  font-style: normal;
  font-weight: normal;
  src: local("Optima"), url("./fonts/OPTIMA.woff") format("woff");
}

@font-face {
  font-family: "Optima Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Optima Italic"), url("./fonts/Optima_Italic.woff") format("woff");
}

@font-face {
  font-family: "Optima Medium";
  font-style: semibold;
  font-weight: 600;
  src: local("Optima Medium"), url("./fonts/Optima Medium.woff") format("woff");
}

@font-face {
  font-family: "Optima", serif;
  font-style: bold;
  font-weight: 700;
  src: local("Optima Bold"), url("./fonts/OPTIMA_B.woff") format("woff");
}
$font-secondary: "Fira Sans", serif;
