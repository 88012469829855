
            @import "src/scss/index.scss";
          
.footer {
  position: relative;
  display: block;
  min-height: calc(100vh - $navbar-height);
  background: #eff3fa !important;
  padding: 0 !important;

  &__image {
    position: relative;
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 100px 0;

    &-column {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      img {
        width: 100%;
      }

      h3 {
        font-family: "Fira Sans";
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: 160%;
        color: #1b3156;
        text-align: left;
        margin: 0;
      }

      &-items {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 25px;
        width: 100%;
        height: 100%;

        ul {
          list-style-type: none;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          grid-column-gap: 32px;
          grid-row-gap: 32px;
          margin: 0;

          a {
            font-family: "Fira Sans";
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.1em;
            color: #1b3156;
            text-decoration: none;
          }
        }

        span {
          font-family: "Fira Sans";
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          text-align: right;
          color: #3661ab;
          margin: 0;

          a {
            color: #3661ab;
            text-decoration-line: underline;
          }
        }
      }
    }
  }

  &__bottom {
    position: relative;
    display: block;
    background: #b1c5e7;

    &-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 70px;

      span {
        font-family: "Fira Sans";
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 0.1em;
        color: #1b3156;
      }

      &-developed {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        strong {
          margin-right: 16px;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
  .footer {
    min-height: calc(100vh - ($navbar-height * 2));
  }
}

@media (min-width: $breakpoint-lg) {
  .footer {
    min-height: calc(100vh - $navbar-height);
  }
}

@media only screen and (max-width: 780px) {
  .footer {
    &__container {
      grid-template-columns: 1fr;

      &-column {
        align-items: center;

        img {
          max-width: 292px;
        }

        h3 {
          max-width: 292px;
          text-align: center;
          margin: 20px 0;
        }

        &-items {
          flex-direction: row;
          align-items: center;

          ul {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            gap: 32px 40px;
            width: 100%;
            padding: 0;
            margin-bottom: 60px;
          }

          span {
            text-align: left;
            flex: 1 1 auto;
            margin: 0 0 25px;

            &:first-child {
              flex: 2 1 auto;
            }

            &:last-child {
              flex: 2 1 auto;
            }
          }
        }
      }
    }

    &__bottom {
      &-content {
        flex-direction: column;
        padding: 18px 0;

        span {
          text-align: center;
          margin-bottom: 8.5px;
        }

        &-developed {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 719px) {
  .footer {
    &__container {
      &-column {
        &-items {
          flex-direction: column;

          ul {
            flex-flow: nowrap;
            flex-direction: column;
            align-items: center;
          }

          span {
            text-align: center;
            flex-direction: column;
            padding: 0 20px;

            &:first-child {
              flex: 2 1 auto;
            }

            &:last-child {
              flex: 2 1 auto;
            }
          }
        }
      }
    }

    &__bottom {
      &-content {
        flex-direction: column;
        padding: 18px 0;

        span {
          text-align: center;
          margin-bottom: 8.5px;
        }

        &-developed {
          margin-bottom: 0;
        }
      }
    }
  }
}
