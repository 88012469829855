
            @import "src/scss/index.scss";
          
.TeamCard {
  color: #1b3156;
  &__image-container {
    height: 447px;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    &,
    img {
      max-width: 292px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    img {
      object-position: top center;
      object-fit: cover;
      height: 100%;
    }
  }
  &__name,
  &__description {
    text-align: center;
    font-weight: 400;
  }
  &__name {
    font-size: 28.43px;
    line-height: 34px;
    color: #2c4f8c;

    margin: 0 0 32px;
    span {
      font-weight: 700;
    }
  }
  &__description {
    font-size: 21.328px;
    line-height: 28px;
    opacity: 0.7;
  }
}

@media (min-width: $breakpoint-md) {
  .TeamCard {
    &__name {
      margin-bottom: 16px;
    }
  }
}
@media (min-width: $breakpoint-lg) {
  .TeamCard {
    display: flex;
    gap: 64px;
    align-items: center;
    &__image-container {
      margin-bottom: 0;
    }
    &__name,
    &__description {
      text-align: left;
    }
  }
}
