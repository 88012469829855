$white-muted-disabled: #c3c3c3;
$white-muted: #848484;
$color-error: #ff7070;
$primary-red: $color-primary-90;

.FormControl {
  position: relative;
  &--error {
    color: $color-error;
    .InputText {
      &,
      &:focus-visible {
        border-color: $color-error !important;
        outline-color: $color-error !important;
      }
    }
  }
  label:not(.Checkbox) {
    display: block;
    margin-bottom: 4px;
    // font-size: 14px;
    font-family: "Fira Sans";
    // font-weight: 600;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 120% */

    /* 100 */

    color: $color-primary-100;
  }
  .InputText {
    width: 100%;
  }
  &__helperText {
    margin: 8px 0 0;
    font-weight: 300;
    font-size: 12px;
    position: absolute;
    bottom: -1rem;
    width: 100%;
    left: 8px;
  }
}

.InputText {
  // background-color: #fafafa;
  background: $color-primary-20;
  /* 100 */
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-family: "Fira Sans";
  /* identical to box height, or 160% */

  /* 60 */

  color: $color-primary-60;
  letter-spacing: -0.02em;
  border: 1px solid #fafafa;
  border-bottom: 4px solid $color-primary-100;
  padding: 18px 18px 18px 30px;
  &::placeholder {
    color: $color-primary-40;
  }
  &:focus-visible {
    border-color: var(--color-text-muted);
    outline-color: var(--color-text-muted);
  }
  &:disabled,
  &:disabled::placeholder {
    color: $color-primary-40;
  }
}

/* The container */
.Checkbox {
  display: inline-block;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Fira Sans";

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  a {
    color: $primary-red;
    text-decoration: underline;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $color-primary-20;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $primary-red;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $color-primary-20;
    // border-radius: 6px;
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
}
