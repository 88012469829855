.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $max-width;
}
.wrapper-left {
  padding-left: $padding-wrapper;
}
.wrapper-right {
  padding-right: $padding-wrapper;
}

// MD
@media (min-width: $breakpoint-md) {
  .wrapper {
    max-width: $max-width-md;
  }
  .wrapper-left {
    padding-left: $padding-wrapper-md;
  }
  .wrapper-right {
    padding-right: $padding-wrapper-md;
  }
}

// XL
@media (min-width: $breakpoint-lg) {
  .wrapper {
    max-width: $max-width-lg;
  }
  .wrapper-left {
    padding-left: $padding-wrapper-lg;
  }
  .wrapper-right {
    padding-right: $padding-wrapper-lg;
  }
}
